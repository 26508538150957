// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from './../Colors'
import { BaseButton, IconInTheButton, type BaseButtonProps } from './BaseButton'

const StyledDefaultButton = styled(BaseButton)`
  color: ${Colors.white100};
  border-color: ${Colors.white100};
  background-color: transparent;

  &:hover {
    color: ${Colors.black100};
    border-color: ${Colors.white100};
    background-color: ${Colors.white100};
  }

  &:disabled {
    color: ${Colors.white10};
    border-color: ${Colors.white10};
    background-color: transparent;
  }

  & ${IconInTheButton} path {
    fill: ${Colors.white100};
    transition: fill 0.25s ease-in-out;
  }

  &:hover ${IconInTheButton} path {
    fill: ${Colors.black100};
  }
`

export const DefaultButton = ({
  children,
  ...otherProps
}: {
  ...BaseButtonProps,
}): React.Node => (
  <StyledDefaultButton {...otherProps}>{children}</StyledDefaultButton>
)
