// @flow

import styled from 'styled-components'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Colors } from './../Colors'

const linkStyles = ({ accented }) => `
  text-decoration: none;

  &,
  &:focus {
    color: ${accented ? Colors.green : Colors.white30};
    text-decoration: underline;
    transition: color ease-in-out 200ms;
    cursor: pointer;
    outline: none;
  }

  &:hover {
    color: ${Colors.green};
    text-decoration: ${accented ? 'initial' : 'underline'};
  }
`

export const Link: any = styled(ReactRouterLink)(props => linkStyles(props))

export const FakeLink: any = styled.span(props => linkStyles(props))

export const NativeLink: any = styled.a(props => linkStyles(props))
