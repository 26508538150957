// @flow

import * as React from 'react'
import styled from 'styled-components'
import FloatingLabel from './FloatingLabel'
import { Input, CheckboxInput } from '.'
import { Colors } from '../Colors'
import CheckboxLabel from './CheckboxLabel'

const TextInputFieldContainer = styled.div`
  position: relative;
  margin-top: 9px;
  border-bottom: 1px solid
    ${({ linkedInputIsFocus, disabled }) =>
      linkedInputIsFocus ? Colors.green : disabled ? 'none' : Colors.white20};
  transition: border-color 250ms ease-in-out;
`

type TextInputFieldProps = {
  label: React.Node,
  errorMessage?: ?string,
  onFocus?: FocusEventHandler,
  onBlur?: FocusEventHandler,
  labelOnFocus?: string,
  disabled?: boolean,
}

export const TextInputField: React.ComponentType<any> = React.forwardRef<
  TextInputFieldProps,
  typeof Input,
>(
  (
    { label, errorMessage, onFocus, onBlur, labelOnFocus, disabled, ...props },
    ref,
  ) => {
    const [linkedInputIsFocus, setLinkedInputIsFocus] = React.useState(false)

    return (
      <TextInputFieldContainer
        linkedInputIsFocus={linkedInputIsFocus}
        disabled={disabled}
      >
        <FloatingLabel
          label={
            labelOnFocus ? (linkedInputIsFocus ? labelOnFocus : label) : label
          }
          errorMessage={errorMessage}
        >
          <Input
            {...props}
            ref={ref}
            disabled={disabled}
            onFocus={(...args) => {
              setLinkedInputIsFocus(true)

              if (onFocus) {
                onFocus(...args)
              }
            }}
            onBlur={(...args) => {
              setLinkedInputIsFocus(false)

              if (onBlur) {
                onBlur(...args)
              }
            }}
          />
        </FloatingLabel>
      </TextInputFieldContainer>
    )
  },
)

const CheckboxInputFieldContainer = styled.div`
  border-bottom: 1px solid ${Colors.white20};
  transition: border-color 250ms ease-in-out;
`

type CheckboxInputFieldProps = {
  label: React.Node,
  errorMessage?: ?string,
}

export const CheckboxInputField: React.ComponentType<any> = React.forwardRef<
  CheckboxInputFieldProps,
  typeof CheckboxInput,
>(({ label, errorMessage, ...props }, ref) => {
  return (
    <CheckboxInputFieldContainer>
      <CheckboxLabel label={label} errorMessage={errorMessage}>
        <CheckboxInput {...props} ref={ref} />
      </CheckboxLabel>
    </CheckboxInputFieldContainer>
  )
})
