// @flow
import * as React from 'react'
import styled from 'styled-components'
import { Colors } from './Colors'
import { RobotoNormal } from './Typography/Fonts'
import CusthomeSvg from './Icons/CusthomeBlackSvg'
import FirefoxLogo from './Icons/firefoxPng.png'
import ChromeLogo from './Icons/chromePng.png'
import EdgeLogo from './Icons/edgePng.png'

const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.background};
  z-index: 200;
`

const Description = styled.div`
  font-family: ${RobotoNormal.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoNormal.weight};
  line-height: 1.5;
  color: ${Colors.white50};
  margin-top: 20px;
  max-width: 330px;
  text-align: center;
`

const LogoCusthomeContainer = styled.div`
  position: absolute;
  bottom: 15px;
  height: 20px;
`

const Link = styled.a`
  margin: 0 6px;
  height: 72px;
  width: 72px;
  transition: transform 0.25s ease-in-out;

  &:hover {
    transform: scale(1.11);
  }
`

const BrowsersContainer = styled.div`
  display: flex;
`

type BrowserProps = { logo: string, link: string, name: string }

const Browser = ({ logo, link, name }: BrowserProps) => (
  <Link href={link} rel="nofollow noopener noreferrer" target="_blank">
    <img src={logo} alt={name} width={72} height={72} />
  </Link>
)

export default (): React.Node => (
  <Wrapper>
    <BrowsersContainer>
      <Browser
        logo={ChromeLogo}
        name="Google Chrome"
        link="https://www.google.com/chrome"
      />
      <Browser
        logo={FirefoxLogo}
        name="Mozilla Firefox"
        link="https://www.mozilla.org/firefox"
      />
      <Browser
        logo={EdgeLogo}
        name="Microsoft Edge"
        link="https://www.microsoft.com/edge"
      />
    </BrowsersContainer>
    <Description>
      A different web browser is required ! Please make your choice above.
    </Description>
    <LogoCusthomeContainer>
      <CusthomeSvg />
    </LogoCusthomeContainer>
  </Wrapper>
)
