// @flow

import * as React from 'react'
import styled from 'styled-components'

const Svg = styled.svg`
  & path {
    transition: fill-opacity 200ms ease-in-out;
  }

  &:hover path {
    fill-opacity:1;
  }
}
`

export default (): React.Node => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="48"
    height="48"
    viewBox="0 0 48 48"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="24" cy="24" r="12" fill="#2F2F2F" />
      <path
        fill="#FFF"
        fillOpacity=".5"
        d="M20.126 18.365L24 22.239l3.874-3.874a1.245 1.245 0 1 1 1.761 1.76L25.761 24l3.874 3.874a1.245 1.245 0 1 1-1.76 1.761L24 25.761l-3.874 3.874a1.245 1.245 0 1 1-1.761-1.76L22.239 24l-3.874-3.874a1.245 1.245 0 1 1 1.76-1.761z"
      />
    </g>
  </Svg>
)
