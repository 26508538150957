// @flow

import styled from 'styled-components'
import { BaseModalBackground } from 'styled-react-modal'
import { Colors } from '../Colors'
import { device } from '../Devices'

const preventMouseMoveEventPropagation = (
  originalOnMouseMoveHandler = null,
) => (e: SyntheticEvent<HTMLDivElement>) => {
  e.stopPropagation()

  if (originalOnMouseMoveHandler) {
    originalOnMouseMoveHandler(e)
  }
}

const BluredModalBackground: any = styled(BaseModalBackground).attrs(props => {
  if (props.onMouseMove !== preventMouseMoveEventPropagation) {
    return {
      onMouseMove: preventMouseMoveEventPropagation(props.onMouseMove),
    }
  }

  return {}
})`
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: ${({ fullScreen, fullScreenOnlyMobile }) =>
    fullScreen | fullScreenOnlyMobile ? 'transparent' : Colors.black50};
  z-index: 1000;
  transition: opacity 0.2s ease-in-out;
  opacity: ${props => props.opacity};

  @media ${device.laptop} {
    background-color: ${Colors.black50};
  }
`

export default BluredModalBackground
