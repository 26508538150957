// @flow

import * as React from 'react'

export default (): React.Element<'svg'> => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="126"
    height="106"
    viewBox="0 0 126 106"
  >
    <defs>
      <path
        id="b"
        d="M70 54v4H10a6 6 0 0 0-5.996 5.775L4 64v32a6 6 0 0 0 5.775 5.996L10 102l60.382.001a10.47 10.47 0 0 0 2.178 4L10 106c-5.523 0-10-4.477-10-10V64c0-5.523 4.477-10 10-10h60z"
      />
      <filter
        id="a"
        width="101.4%"
        height="103.8%"
        x="-.7%"
        y="-1%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feComposite
          in="shadowOffsetOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowOffsetOuter1"
        />
        <feColorMatrix
          in="shadowOffsetOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0"
        />
      </filter>
      <path
        id="d"
        d="M126 96c0 5.523-4.477 10-10 10H84c-5.523 0-10-4.477-10-10V10c0-5.523 4.477-10 10-10h32c5.523 0 10 4.477 10 10v86zm-4 0V10a6 6 0 0 0-6-6H84a6 6 0 0 0-6 6v86a6 6 0 0 0 6 6h32a6 6 0 0 0 6-6z"
      />
      <filter
        id="c"
        width="101.9%"
        height="101.9%"
        x="-1%"
        y="-.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feComposite
          in="shadowOffsetOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowOffsetOuter1"
        />
        <feColorMatrix
          in="shadowOffsetOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fill="#000">
        <use filter="url(#a)" xlinkHref="#b" />
        <use fillOpacity=".35" xlinkHref="#b" />
      </g>
      <g fill="#000">
        <use filter="url(#c)" xlinkHref="#d" />
        <use fillOpacity=".35" xlinkHref="#d" />
      </g>
      <g
        stroke="#2EC973"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      >
        <path d="M45 46c0-15 8.993-21 21-21M59 19l7 6-6 7" />
      </g>
    </g>
  </svg>
)
