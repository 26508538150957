// @flow

import * as React from 'react'
import Modal from './../../GuidelinesNew/Modal/index'
import Customer from '../Customer'
import CustomerRegistrationForm from './CustomerRegistrationForm'
import CustomerLogInForm from './CustomerLogInForm'
import RequestNewPasswordForm from './RequestNewPasswordForm'

export const LOGIN_MODE = 'LOGIN_MODE'
export const REGISTRATION_MODE = 'REGISTRATION_MODE'
export const REQUEST_NEW_PASSWORD_MODE = 'REQUEST_NEW_PASSWORD_MODE'

type OneErrorPayload = {| field: ?string, message: string |}

type Props = {|
  isOpen: boolean,
  defaultMode: typeof LOGIN_MODE | typeof REGISTRATION_MODE,
  closable?: boolean,
  onClose: () => any,
  doRegistration: (
    firstName: string,
    email: string,
    password: string,
  ) => Promise<{
    customer: ?Customer,
    errors: OneErrorPayload[],
  }>,
  doLogIn: (
    email: string,
    password: string,
  ) => Promise<{
    customer: ?Customer,
    errors: OneErrorPayload[],
  }>,
  doRequestNewPassword: (email: string) => Promise<OneErrorPayload[]>,
  onConnectionSuccess: (customer: Customer) => any,
  onRequestNewPasswordExitedAfterSuccess: () => any,
  privacyPoliciesPageUrl: ?string,
  termsOfServicesPageUrl: ?string,
|}

type State = {|
  isOpen: boolean,
  /* eslint-disable flowtype/space-after-type-colon */
  mode:
    | typeof LOGIN_MODE
    | typeof REGISTRATION_MODE
    | typeof REQUEST_NEW_PASSWORD_MODE,
|}

class CustomerConnectionModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      isOpen: props.isOpen,
      mode: props.defaultMode,
    }
  }

  static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.isOpen === true && state.isOpen === false) {
      return { ...state, isOpen: true, mode: props.defaultMode }
    }

    if (props.isOpen === false && state.isOpen === true) {
      return { ...state, isOpen: false }
    }

    return state
  }

  switchToRegistrationMode = () => {
    this.setState({ mode: REGISTRATION_MODE })
  }

  switchToLogInMode = () => {
    this.setState({ mode: LOGIN_MODE })
  }

  switchToRequestNewPasswordMode = () => {
    this.setState({ mode: REQUEST_NEW_PASSWORD_MODE })
  }

  render() {
    const {
      closable,
      isOpen,
      doRegistration,
      doLogIn,
      doRequestNewPassword,
      onClose,
      onConnectionSuccess,
      onRequestNewPasswordExitedAfterSuccess,
      privacyPoliciesPageUrl,
      termsOfServicesPageUrl,
    } = this.props
    const { mode } = this.state

    return (
      <Modal
        closable={closable}
        fullScreen
        isOpen={isOpen}
        onRequestClose={() => onClose()}
      >
        <React.Fragment>
          {mode === REGISTRATION_MODE ? (
            <CustomerRegistrationForm
              onRegistration={doRegistration}
              onRegistrationSuccess={onConnectionSuccess}
              switchToLogInMode={this.switchToLogInMode}
              privacyPoliciesPageUrl={privacyPoliciesPageUrl}
              termsOfServicesPageUrl={termsOfServicesPageUrl}
            />
          ) : null}

          {mode === LOGIN_MODE ? (
            <CustomerLogInForm
              onLogIn={doLogIn}
              onLogInSuccess={onConnectionSuccess}
              switchToRegistrationMode={this.switchToRegistrationMode}
              switchToRequestNewPasswordMode={
                this.switchToRequestNewPasswordMode
              }
            />
          ) : null}

          {mode === REQUEST_NEW_PASSWORD_MODE ? (
            <RequestNewPasswordForm
              onRequestNewPassword={doRequestNewPassword}
              onRequestNewPasswordSuccess={() => {}}
              onExitedAfterSuccess={
                !closable ? onRequestNewPasswordExitedAfterSuccess : null
              }
              switchToLogInMode={this.switchToLogInMode}
            />
          ) : null}
        </React.Fragment>
      </Modal>
    )
  }
}

export default (CustomerConnectionModal: React.ComponentType<any>)
