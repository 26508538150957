// @flow

import * as React from 'react'
import styled from 'styled-components'
import { RobotoNormal } from './Typography/Fonts'
import RotateDeviceSvg from './Icons/RotateDeviceSvg'
import { Colors } from './Colors'
import CusthomeSvg from './Icons/CusthomeBlackSvg'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.background};
  z-index: 2000;
`

const Description = styled.div`
  font-family: ${RobotoNormal.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoNormal.weight};
  line-height: 1.5;
  color: ${Colors.white50};
  margin-top: 10px;
  max-width: 330px;
  text-align: center;
`

const LogoCusthomeContainer = styled.div`
  position: absolute;
  bottom: 15px;
  height: 20px;
`

export const RotateDeviceScreen = (): React.Node => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <RotateDeviceSvg />
      <Description>{t('rotateDevice')}</Description>
      <LogoCusthomeContainer>
        <CusthomeSvg />
      </LogoCusthomeContainer>
    </Wrapper>
  )
}

type WINDOW_SIZES = {|
  innerHeight: number,
  innerWidth: number,
  outerHeight: number,
  outerWidth: number,
|}

const canRotate = () => typeof window.orientation !== 'undefined'

function getSize(): WINDOW_SIZES {
  return {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    outerHeight: window.outerHeight,
    outerWidth: window.outerWidth,
  }
}

const useWindowSize = (): WINDOW_SIZES => {
  const [windowSize, setWindowSize] = React.useState(getSize())

  const handleResize = event => {
    event.preventDefault()
    setWindowSize(getSize())
    event.stopPropagation()
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize
}

const RotateDeviceScreenOnlyOnSmallLanscapeOrientatedScreen = (): React.Node => {
  const { innerHeight, innerWidth } = useWindowSize()

  const isLandscape = innerHeight < innerWidth
  const isSmallHeight = innerHeight < 600

  if (canRotate() && isLandscape && isSmallHeight) {
    return <RotateDeviceScreen />
  }

  return null
}

export default RotateDeviceScreenOnlyOnSmallLanscapeOrientatedScreen
