// @flow
import * as React from 'react'
import { Link } from 'react-router-dom'
import { programPreviewHomePageRoute } from '../urls'
import PageNotFoundSvg from './Icons/PageNotFoundSvg'
import styled from 'styled-components'
import { Colors } from './Colors'
import { RobotoNormal } from './Typography/Fonts'
import CusthomeSvg from './Icons/CusthomeBlackSvg'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
`

const TextContainer = styled.div`
  font-family: ${RobotoNormal.fontFamily};
  font-weight: ${RobotoNormal.weight};
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  margin-top: 10px;
  max-width: 300px;
  color: ${Colors.white50};
`

const BackToHome = styled(Link)`
  color: ${Colors.green};
  display: block;
  width: max-content;
  margin: auto;
  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: none;
  }
`

const LogoCusthomeContainer = styled.div`
  position: absolute;
  bottom: 15px;
  height: 20px;
`

export default (): React.Node => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <PageNotFoundSvg />
      <TextContainer>
        {t('pageNotFound')}
        <BackToHome to={programPreviewHomePageRoute()}>
          {t('backToHome')}
        </BackToHome>
      </TextContainer>
      <LogoCusthomeContainer>
        <CusthomeSvg />
      </LogoCusthomeContainer>
    </Wrapper>
  )
}
