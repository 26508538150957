// @flow

import * as React from 'react'
import styled from 'styled-components'
import { Colors } from './../Colors'
import { BaseButton, IconInTheButton, type BaseButtonProps } from './BaseButton'

const StyledPrimaryButton = styled(BaseButton)`
  color: ${Colors.green};
  border-color: ${Colors.green};
  background-color: transparent;

  &:hover {
    color: ${Colors.black100};
    border-color: ${Colors.green};
    background-color: ${Colors.green};
  }

  &:disabled {
    color: ${Colors.white10};
    border-color: ${Colors.white10};
    background-color: transparent;
  }

  & ${IconInTheButton} path {
    fill: ${Colors.green};
    transition: fill 0.25s ease-in-out;
  }

  &:hover ${IconInTheButton} path {
    fill: ${Colors.black100};
  }
`

export const PrimaryButton = ({
  children,
  ...otherProps
}: {
  ...BaseButtonProps,
}): React.Node => (
  <StyledPrimaryButton {...otherProps}>{children}</StyledPrimaryButton>
)
