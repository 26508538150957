// @flow

export const referrers = {
  FROM_CUSTOMER_AREA: 'from_customer_area',
  FROM_SELLER_AREA: 'from_seller_area',
}

export type REFERRER_VALUE = 'from_customer_area' | 'from_seller_area'

export const programPreviewHomePageRoute = (
  parameter?: string,
  value?: string | number,
): string => {
  if (parameter && value !== undefined && value !== null) {
    const parsedUrl = new URL(window.location.href)
    parsedUrl.searchParams.set(parameter, value.toString())
    return '/program' + parsedUrl.search
  } else {
    return '/program'
  }
}

export const configuratorHomePageRoute = (
  lotId: string,
  referrer: ?REFERRER_VALUE = null,
): string =>
  !referrer ? `/lot/${lotId}` : `/lot/${lotId}?referrer=${referrer}`

export const virtualTourPageRoute = (lotId: string): string => `/lot/${lotId}`

export const customerAreaHomePageRoute = (): string => `/customer`

export const customerAreaLoginPageRoute = (): string => `/customer/login`

export const customerAreaRegisterPageRoute = (): string => `/customer/register`

export const customerAreaForgottenPasswordPageRoute = (): string =>
  `/customer/password/oops`

export const customerAreaResetPasswordPageRoute = (): string =>
  `/customer/password/reset`

export const customerAreaSavedConfigurationsPageRoute = (): string =>
  `/customer/configurations`

export const customerAreaSavedConfigurationDetailsPageRoute = (
  configurationId: string,
): string => `/customer/configuration/${configurationId}`
