// @flow

export const RobotoLight = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  weight: 300,
}

export const RobotoNormal = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  weight: 400,
}

export const RobotoMedium = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  weight: 500,
}

export const RobotoBold = {
  fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  weight: 700,
}
