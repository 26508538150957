// @flow

import { type History, type Location } from 'history'

/**
 * To debug GA events, the simplier way is to install the official `Google Analytics Debugger`
 * extension:
 *
 * @see https://chrome.google.com/webstore/detail/google-analytics-debugger/jnkmfdileelhofjcijamephohjechhna
 */
export class GoogleAnalyticsService {
  listenToLocationChange(history: History<any>) {
    history.listen((location: Location) => {
      const page = location.pathname + location.search + location.hash

      if (window.ga) {
        window.ga('set', 'location', window.location.href)
        window.ga('set', 'page', page)
        window.ga('send', 'pageview')
      } else {
        console.warn(
          'You try to report the event to GA but the `gtag` function was not found',
        )
      }
    })
  }

  reportEvent(
    eventCategory: string,
    eventAction: ?string,
    eventLabel: ?string,
  ) {
    if (window.ga) {
      window.ga('send', 'event', eventCategory, eventAction, eventLabel)
    } else {
      console.warn(
        'You try to report the event to GA but the `gtag` function was not found. The event:',
        { eventCategory, eventAction, eventLabel },
      )
    }
  }
}
