// @flow

import * as React from 'react'

export default (): React.Element<'svg'> => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="172"
    height="67"
    viewBox="0 0 172 67"
  >
    <defs>
      <path
        id="b"
        d="M115.956 38.351c2.763 0 3.743.981 3.743 3.746v6.244c0 2.764-.98 3.745-3.743 3.745h-4.545v8.83c0 2.765-.98 3.746-3.743 3.746H98.49c-2.852 0-3.743-.98-3.743-3.746v-8.83H67.743c-2.763 0-3.743-.98-3.743-3.745v-5.887c0-2.943.178-4.013 1.96-6.065L93.054 4.014c1.782-2.052 2.852-2.676 5.793-2.676h8.822c2.763 0 3.743.98 3.743 3.746V38.35h4.545zm-21.21-15.073L82.27 38.351h12.477V23.278zm29.944-.089C124.69 9.01 133.96 0 150.09 0c16.13 0 25.398 9.008 25.398 23.19v19.532c0 14.36-9.268 23.278-25.399 23.278-16.13 0-25.399-8.919-25.399-23.278V23.189zm33.152-.713c0-4.37-2.94-6.868-7.753-6.868-4.812 0-7.753 2.497-7.753 6.868v20.96c0 4.459 2.94 6.956 7.753 6.956 4.813 0 7.753-2.497 7.753-6.957v-20.96zm74.415 15.875c2.763 0 3.743.981 3.743 3.746v6.244c0 2.764-.98 3.745-3.743 3.745h-4.545v8.83c0 2.765-.98 3.746-3.743 3.746h-9.18c-2.851 0-3.742-.98-3.742-3.746v-8.83h-27.003c-2.763 0-3.743-.98-3.743-3.745v-5.887c0-2.943.178-4.013 1.96-6.065l27.092-32.375c1.783-2.052 2.852-2.676 5.793-2.676h8.823c2.763 0 3.743.98 3.743 3.746V38.35h4.545zm-21.21-15.073L198.57 38.351h12.477V23.278z"
      />
      <filter
        id="a"
        width="100.6%"
        height="103%"
        x="-.3%"
        y="-.8%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feComposite
          in="shadowOffsetOuter1"
          in2="SourceAlpha"
          operator="out"
          result="shadowOffsetOuter1"
        />
        <feColorMatrix
          in="shadowOffsetOuter1"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.05 0"
        />
      </filter>
    </defs>
    <g fill="#000" fillRule="evenodd" transform="translate(-64)">
      <use filter="url(#a)" xlinkHref="#b" />
      <use fillOpacity=".35" xlinkHref="#b" />
    </g>
  </svg>
)
