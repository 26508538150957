// @flow
import * as React from 'react'
import styled from 'styled-components'
import uniqid from 'uniqid'
import { CheckboxInput, ErrorMessage } from '.'
import { Colors } from '../Colors'
import { RobotoMedium } from '../Typography/Fonts'

const LabelWrapper = styled.div`
  padding: 20px 0;
`

const CheckboxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${CheckboxInput} {
    flex-shrink: 0;

    ${({ linkedInputIsErrored }) =>
      linkedInputIsErrored ? `border-color: ${Colors.red};` : null};

    outline: none;
  }
`

const Label = styled.label`
  padding: 0;
  font-family: ${RobotoMedium.fontFamily};
  font-weight: ${RobotoMedium.weight};
  font-size: 12px;
  line-height: 16px;
  color: ${Colors.white30};
  cursor: pointer;
`

const CheckboxErrorMessage = styled(ErrorMessage)`
  padding: 4px 0px 0px 48px;
`

const RadioLabel = ({
  label,
  errorMessage,
  children,
  ...props
}: {|
  label: React.Node,
  errorMessage: ?string,
  children: React.Node,
|}): React.Node => {
  const originalInput = React.Children.only(children)
  const { id, onChange } = originalInput.props

  const [inputId] = React.useState(id || `input-${uniqid()}`)

  const checkboxInputElement = React.cloneElement(originalInput, {
    id: inputId,

    onChange: (e, ...args) => {
      if (onChange) {
        onChange(e, ...args)
      }
    },
  })

  return (
    <LabelWrapper>
      <CheckboxRow linkedInputIsErrored={!!errorMessage}>
        {checkboxInputElement}

        <Label htmlFor={inputId} message={errorMessage}>
          {label}
        </Label>
      </CheckboxRow>

      {errorMessage ? (
        <CheckboxErrorMessage>{errorMessage}</CheckboxErrorMessage>
      ) : null}
    </LabelWrapper>
  )
}

export default RadioLabel
